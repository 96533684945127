export const BTN_CLASSES = "px-4 py-2 rounded focus:outline-none"

export const COLOR_CLASSES = {
    blue: "bg-blue-500 hover:bg-blue-600 text-white",
    orange: "bg-primorange text-white hover:bg-primdarkorange",
    yellow: "bg-yellow-500 hover:bg-yellow-600 text-black",
    green: "bg-green-500 text-white hover:bg-green-600",
    red: "bg-red-500 text-white hover:bg-red-600",
    transparent: "text-black bg-blue-300 hover:bg-orange-400",
};

export const CARD_BG_CLASSES = {
    green: "hover:bg-green-500",
    blue: "hover:bg-blue-500",
    orange: "hover:bg-primorange",
    yellow: "hover:bg-yellow-500",
    red: "hover:bg-red-500",
};

export const CARD_RING_CLASSES = {
    green: "hover:ring-green-500",
    blue: "hover:ring-blue-500",
    orange: "hover:ring-primorange",
    yellow: "hover:ring-yellow-500",
    red: "hover:ring-red-500",
};

export const CARD_TEXT_CLASSES = {
    green: "group-hover:text-white",
    blue: "group-hover:text-white",
    orange: "group-hover:text-white",
    yellow: "group-hover:text-black",
    red: "group-hover:text-white",
};

export const CARD_ICON_CLASSES = {
    green: "fill-green-500 group-hover:fill-white",
    blue: "fill-blue-500 group-hover:fill-white",
    orange: "fill-primorange group-hover:fill-white",
    yellow: "fill-yellow-500 group-hover:fill-black",
    red: "fill-red-500 group-hover:fill-white",
};
